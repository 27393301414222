import React from "react";
import {
  IoCheckboxOutline,
  IoFlash,
  IoPaperPlane,
  IoPricetag,
  IoPhonePortrait,
  IoDisc,
  IoCalculator,
  IoSettings,
  IoHappy,
  IoPeople,
  IoCellular,
  IoHeadset,
  IoCheckmark,
  IoHome,
  IoShieldCheckmark,
} from "react-icons/io5";
import { Link } from "gatsby";
import { useModal } from "../components/useModal";

import Image from "../components/image";
import {
  Feature,
  CustomerQuote,
  Modal,
  ImageGraphic,
  CallToAction,
} from "../components/site";
import {
  Button,
  Container,
  Wrapper,
  Row,
  Box,
  FlexButtons,
} from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";

const FarmersPayroll = (props) => {
  const [itemModalOpen, setItemModalOpen, toggleModal] = useModal();

  const industry_benefits = [
    "Stay compliant with minimum wage top up",
    "Easily manage accommodation & piecemeal rates",
    "Track to-the-minute timesheets & breaks",
    "Speed less time on admin",
  ];

  return (
    <Layout>
      <Seo
        title="Payroll Software for Farmers | PayHero"
        description="Farm payroll made super easy. Timesheets, minimum wage top up & payroll in one simple online tool. Get started for free with compliant & easy to use payroll."
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper stackGap={70} noPaddingBottom>
          <Row stackGap={80} alignCenter>
            <Box size={50} stackGap={50} centerOnMobile>
              <Box stackGap={20}>
                <h1 className="-lg -primary badge">
                  Payroll for Farmers & Agriculture
                </h1>
                <h2 className="h1 -fontLarge">
                  Farm payroll{" "}
                  <span className="-fontPrimary">made super easy</span>
                </h2>
                <Box stackGap={15}>
                  {industry_benefits.map((item, i) => {
                    return (
                      <Row
                        alignCenter
                        noBreak
                        stackGap={10}
                        justify="flex-start"
                        key={i}
                        css={{ fontSize: "1.2rem", textAlign: "left" }}
                        centerOnMobile
                      >
                        <IoCheckmark
                          css={{
                            fontSize: "1.8rem",
                            color: "#5eb22e",
                            flex: "0 0 auto",
                          }}
                        />
                        <span>{item}</span>
                      </Row>
                    );
                  })}
                </Box>
              </Box>
              <Box stackGap={15}>
                <FlexButtons>
                  <Button className="primary -lg gtm-cta" to="/signup">
                    Try 14 Days Free
                  </Button>
                  <Button
                    className="grey -lg gtm-cta"
                    onClick={toggleModal}
                    atag
                  >
                    Book a Sales Call
                  </Button>
                </FlexButtons>
                <p css={{ color: "#999" }}>
                  Get started in minutes, no credit card required.
                </p>
              </Box>
            </Box>
            <Box size={50} css={{ padding: "40px" }}>
              <ImageGraphic variant="1" />
              <Image
                filename="FarmerHero.jpg"
                alt="Payroll for Farmers"
                centerImage
                addShadow
                rounded
              />
            </Box>
          </Row>
          <hr />
        </Wrapper>
      </Container>
      <Container>
        <Wrapper stackGap={150}>
          <Row stackGap={100} alignCenter mobileReverse>
            <Box size={50}>
              <ImageGraphic variant="2" />
              <Image
                alt="Timesheets, leave, expenses & payslips on mobile"
                filename="PayHero_Mobile_Farming.png"
                maxWidth={500}
                addShadow
                centerImage
                rounded
              />
            </Box>
            <Box size={50} stackGap={40} centerOnMobile>
              <Box stackGap={10}>
                <h2 className="h4 -fontDark -fontNormal">
                  Employee mobile app
                </h2>
                <h4 className="h2">
                  Give your team the tools they need to complete key payroll
                  tasks from the palm of their hand
                </h4>
              </Box>
              <Feature
                icon={<IoPhonePortrait key={0} />}
                title="Timesheets in their pocket"
                description="From on the farm, employees can submit timesheets against different work items or cost centres by entering start, end and break times or using the GPS start/stop timer. They can access their old payslips too, so you don’t need to dig them out."
              />
              <Feature
                icon={<IoPricetag key={0} />}
                title="Capture expenses with ease"
                description="Expense claims couldn’t be easier. Your employees can take a photo of a receipt, categorise it and submit the expense for approval from their phone."
              />
              <Feature
                icon={<IoPaperPlane key={0} />}
                title="Easy leave requests"
                description="Employees can submit leave requests, check the approval status and balances and see a record of leave already taken and booked in with a simple calendar view."
              />
            </Box>
          </Row>
          <CustomerQuote
            quote="PayHero has made payroll quick and easy. The support team is great as well and they help get me sorted whenever I need a hand."
            name="Garry McCorkindale"
            company="Glenside Farm"
            pic="Garry_Circle.png"
            large
            center
          />
          <Row stackGap={100} alignCenter>
            <Box size={50} stackGap={40} centerOnMobile>
              <Box stackGap={10}>
                <h2 className="h4 -fontDark -fontNormal">
                  Stress-free payroll
                </h2>
                <h4 className="h2">
                  Pay employees right with payroll software, built for
                  businesses like yours
                </h4>
              </Box>
              <Feature
                icon={<IoHome key={0} />}
                title="Accommodation and piecemeal"
                description="It’s straightforward to set up and manage farm specific payroll needs in PayHero. If you’re paying employees piecemeal rates for the work they do or have accommodation allowances in your contracts, our support team can help you get those set up."
              />
              <Feature
                icon={<IoCellular key={0} />}
                title="Pay wages from anywhere"
                description={[
                  <span>With </span>,
                  <Link to="/payroll">online payroll</Link>,
                  <span>
                    {" "}
                    you can pay your staff from wherever you are. If you set
                    your payroll to Autopilot, PayHero will run your payroll on
                    payday and send you an email with all the details. You can
                    finalise the pay there and then without having to login.
                  </span>,
                ]}
              />
              <Feature
                icon={<IoPaperPlane key={0} />}
                title="Integrate with Xero"
                description="Automatically send payroll data through to Xero. Keep it simple or assign wage costs to different account codes and tracking categories for a more detailed breakdown."
              />
            </Box>
            <Box size={50}>
              <ImageGraphic variant="2" />
              <Image
                alt="Sync timesheets from mobile directly to payroll"
                filename="PayHero_Review_Farming.png"
                addShadow
                centerImage
                rounded
              />
            </Box>
          </Row>
          <Row stackGap={100} alignCenter mobileReverse>
            <Box size={50}>
              <Image
                filename="PayrollForFarmers_PayHero.jpg"
                alt="Payroll software compliance for Farmers"
                centerImage
                rounded
              />
            </Box>
            <Box size={50} stackGap={40} centerOnMobile>
              <Box stackGap={10}>
                <h2 className="h4 -fontDark -fontNormal">
                  Simplify compliance
                </h2>
                <h4 className="h2">
                  Look after your farm and let PayHero take care of the tricky
                  payroll stuff
                </h4>
              </Box>
              <Feature
                icon={<IoCheckboxOutline key={0} />}
                title="Minimum wage top up"
                description="PayHero ensures that salaried farm staff who work variable hours, or earn piecemeal rates, are always paid at least the minimum wage, so you can be sure you’re compliant and your employees are paid fairly."
              />
              <Feature
                icon={<IoShieldCheckmark key={0} />}
                title="Stay onside with the Holidays Act"
                description="Farming & agriculture is a hotbed of potentially tricky payroll scenarios with part time and variable hour staff and regularly changing work patterns. PayHero follows the latest MBIE Holidays Act guidance to ensure your employees maintain the correct holiday entitlements, even when things change."
              />
              <Feature
                icon={<IoFlash key={0} />}
                title="Automated payday filing"
                description={[
                  <Link to="/payday-filing">Payday filing</Link>,
                  <span>
                    {" "}
                    is a piece of cake in PayHero – just connect your account to
                    IRD and we’ll automatically send your payroll information
                    through after every pay.
                  </span>,
                ]}
              />
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container bg="lightGrey">
        <Wrapper noPaddingBottom>
          <Box className="-textCenter">
            <h2>More reasons why farmers choose PayHero for payroll</h2>
            <h4>
              Timesheets, minimum wage top up & payroll in one simple online
              tool.
            </h4>
          </Box>
          <Box stackGap={40}>
            <Row stackGap={40} className="-textCenter">
              <Box size={33}>
                <h3>
                  <IoPeople />
                  <br />
                  Pay your staff in minutes
                </h3>
                <p>
                  Time entries, leave & expenses submitted by your employees
                  from their mobile app show up instantly & automatically in
                  PayHero, ready for processing payroll.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoDisc />
                  <br />
                  Always accurate
                </h3>
                <p>
                  Every time payroll and labour laws change, we update PayHero
                  so you’re always fully compliant with tax laws and the
                  Holidays Act. No downloads or time consuming updates for you –
                  just business as usual.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoCalculator />
                  <br />
                  Automated public holiday calculations
                </h3>
                <p>
                  PayHero determines whether a public holiday is an otherwise
                  working day for an employee based on their recent work pattern
                  and calculates their entitlements accordingly.
                </p>
              </Box>
            </Row>
            <Row stackGap={40} className="-textCenter">
              <Box size={33}>
                <h3>
                  <IoSettings />
                  <br />
                  Set and forget payroll
                </h3>
                <p>
                  With Payroll AutoPilot, PayHero will run payroll for you.
                  You’ll get an email with all the details so you can approve
                  the pay without having to login.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoHeadset />
                  <br />
                  Support you can rely on
                </h3>
                <p>
                  Our friendly Wellington-based support team is available by
                  phone or email to help you out whenever you need it.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoHappy />
                  <br />
                  Try before you buy
                </h3>
                <p>
                  With a fully featured{" "}
                  <Link to="/signup">free 14 day trial</Link>, you can take
                  PayHero for a risk-free test run before committing.
                </p>
              </Box>
            </Row>
          </Box>
          <Image
            filename="PayHeroHero_Cropped_Farming.png"
            alt="PayHero"
            maxWidth={1000}
            centerImage
            addShadow
          />
        </Wrapper>
      </Container>
      <CallToAction showSalesButton />
      <Modal
        isActive={itemModalOpen}
        embedURL="https://calendly.com/d/ynv-22p-2r5"
        embedTitle="Book a Sales Call | PayHero Sales"
        handleClose={() => setItemModalOpen(false)}
      />
    </Layout>
  );
};

export default FarmersPayroll;
